// src/components/RewardNotificationWrapper.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWalletWithPurchaseRewards } from '../../hooks/cart';
import NextRewardNotification from './NextRewardNotification';
import WalletNotification from './WalletNotification';
import { resetRewardsState, setFilledRewardNotification, setHasUnlockedReward, setRewardStatus, setWalletCreditShown, setWalletRewards } from '../../redux/slices/rewardSlice';

const RewardNotificationWrapper = () => {
  const dispatch = useDispatch();

  // Access state from Redux store
  const {
    isWalletCreditShown,
    isFilledRewardNotifcation,
    currentAvailedWalletReward,
    currentUnlockedTier,
    currentSubtotal,
    requiredSubtotal,
    rewardAmount,
    rewardStatus,
    hasUnlockedReward,
  } = useSelector((state) => state.rewards);
  const { isLoggedIn } = useSelector(state => state.user);

  // Fetch data using custom hook
  const {
    data: rewardData,
    data: {
      wallet_rewards: walletRewards = {},
      next_reward: nextReward = null, // Allow nextReward to be null
      current_subtotal: currentSubtotalFromHook = 0,
    } = {}
  } = useWalletWithPurchaseRewards() || {};

  // Effect to reset rewards state when isLoggedIn changes to true
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(resetRewardsState());
    }
  }, [isLoggedIn, dispatch]);

  const {
    required_subtotal: requiredSubtotalFromHook = 0,
    amount = 0,
  } = nextReward || {};

  // Sync data with Redux
  useEffect(() => {
    if (rewardData?.current_subtotal === undefined) return;
    dispatch(setWalletRewards({
      walletRewards,
      nextReward: { required_subtotal: requiredSubtotalFromHook, amount },
      currentSubtotal: currentSubtotalFromHook,
    }));

    // Update reward status based on the cart and reward status
    if (currentAvailedWalletReward > 0) {
      if (!hasUnlockedReward) {
        dispatch(setRewardStatus('unlocked'));
        dispatch(setHasUnlockedReward(true));
      }
    } else if (currentSubtotalFromHook >= requiredSubtotalFromHook) {
      dispatch(setRewardStatus('unlocking'));
    } else {
      dispatch(setRewardStatus('away'));
    }
  }, [walletRewards, rewardData, requiredSubtotalFromHook, amount, currentSubtotalFromHook, currentAvailedWalletReward, hasUnlockedReward, dispatch]);

  useEffect(() => {
    let startTimeoutId = null;
    let endTimeoutId = null;

    if (currentAvailedWalletReward && !hasUnlockedReward) {
      dispatch(setFilledRewardNotification(true));
      startTimeoutId = setTimeout(() => {
        dispatch(setFilledRewardNotification(false));
        dispatch(setWalletCreditShown(true));
        clearTimeout(startTimeoutId);
      }, 900);

      endTimeoutId = setTimeout(() => {
        dispatch(setWalletCreditShown(false));
        clearTimeout(endTimeoutId);
      }, 3000);
    }

    return () => {
      if (startTimeoutId || endTimeoutId) {
        clearTimeout(startTimeoutId);
        clearTimeout(endTimeoutId);
      }
    };
  }, [currentAvailedWalletReward, hasUnlockedReward, dispatch]);

  const isUnlockingCredit =
    isFilledRewardNotifcation && currentSubtotal && currentUnlockedTier && currentSubtotal >= currentUnlockedTier;

  if (isWalletCreditShown && currentAvailedWalletReward && !hasUnlockedReward) {
    return <WalletNotification amount={currentAvailedWalletReward} isWalletCreditShown={isWalletCreditShown} />;
  }

  if (rewardStatus === 'unlocking' || rewardStatus === 'away' || requiredSubtotal !== 0) {
    return (
      <NextRewardNotification
        currentSubtotal={currentSubtotal}
        requiredSubtotal={requiredSubtotal}
        rewardAmount={rewardAmount}
        isUnlockingCredit={rewardStatus === 'unlocking'}
      />
    );
  }

  return null;
};

export default RewardNotificationWrapper;
