import React from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import appContext from '../../Context/appContext';

const NextRewardNotification = props => {
  // const {
  //   state: {
  //     cart: { cartData: { walletWithPurchaseAmount } = {} }
  //   }
  // } = useContext(appContext);
  const { walletWithPurchaseAmount } = useSelector(state => state.cart?.cartData) || {}

  const { currentSubtotal, requiredSubtotal, rewardAmount, isUnlockingCredit } = props;

  const amountToRequiredSubtotal = requiredSubtotal - currentSubtotal;
  if (!Number(currentSubtotal)) return null
  if (!requiredSubtotal) return null

  return (
    <div className={`reward-cart-wrapper yy invoice-rewards-section reward-cart-wrapper-next reward-first-state ${!!walletWithPurchaseAmount ? 'previous-tier-claimed' : ''}`}>
      <div className="reward-cart-widget">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex d-flex align-items-center">
            <i className="icon-gift-outline white-color" />
            <p className="mb-0 ml-2">
              {isUnlockingCredit ? (
                <>Unlocking reward...</>
              ) : (
                <>
                  <>{`You are $${(amountToRequiredSubtotal > 0 ? amountToRequiredSubtotal : 0).toFixed(2)} away from getting`}</>
                  <>{`${!!walletWithPurchaseAmount ? ' additional ' : ' '}`}</>
                  <span className="fs-16 bold">{`$${rewardAmount} credit`}</span>
                </>
              )}
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-end half-rem-mt">
          <span className="fs-14">{`$${currentSubtotal} / $${requiredSubtotal}`}</span>
        </div>
      </div>

      <div className="qtn-progress">
        <div className="qtn-progress-bar" role="progressbar" style={{ width: `${(currentSubtotal / requiredSubtotal) * 100}%` }}>
          <span className="p-absolute" />
        </div>
      </div>
    </div>
  );
};

export default NextRewardNotification;
